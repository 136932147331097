import React from 'react'

export default () => (
    <div>
<p><strong>POLITYKA PRYWATNOŚCI I COOKIES</strong></p>
<p>W związku z wejściem w życie w dniu 25 maja 2018 r. Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679
    z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
    swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Dz. Urz. UE L 119 s.1) tzw. RODO –
    zobowiązujemy się do poszanowania Twojego prawa do prywatności w Internecie oraz dołożenia wszelkich starań
    w zakresie właściwego zabezpieczenia wszelkich udostępnionych danych osobowych. Jednocześnie podkreślamy, że stronę
    internetową www.kontenerowy.pl /www.e-containers.eu („<strong>Serwis</strong>”) można przeglądać bez podawania
    swoich personaliów.</p>
<p><strong>Administrator danych osobowych</strong></p>
<p>Administratorem Twoich danych osobowych („<strong>Administrator</strong>”) jest RR Media spółka z ograniczoną
    odpowiedzialnością z siedzibą w Gdyni (81-156) przy ul. Janka Wiśniewskiego 31 lok. 328, e-mail
    biuro@e-containers.eu, tel. +48 603 244 040.</p>
<p><strong>Inspektor Ochrony Danych</strong></p>
<p>Administrator nie powołał Inspektora Ochrony Danych. Zobowiązujemy się jednak podjąć odpowiednie działania mające
    na celu ochronę przed nieupoważnionym dostępem lub ujawnieniem Twoich danych osobom trzecim. Nasze bazy są
    zabezpieczone przed wglądem osób trzecich. W razie jakichkolwiek wątpliwości związanych z przetwarzaniem Twoich
    danych, nie wahaj się skontaktować z Administratorem używając wyżej wskazanych danych. </p>
<p><strong>Cele i podstawa prawna przetwarzania danych, prawnie uzasadniony interes przetwarzania</strong></p>
<p>Poniżej przedstawiamy Ci wszystkie występujące standardowo w działalności Administratora cele przetwarzania danych
    przez Administratora. Szczegółowy cel przetwarzania Twoich danych jest Ci każdorazowo komunikowany przez
    Administratora w momencie pozyskiwana Twoich danych. Twoje dane osobowe mogą być zatem przetwarzane celem:</p>
<ul>
    <li>zawarcia i wykonania umowy/umów, w tym zapewnienia obsługi transakcji, rozwiązywania problemów i realizacji
        Twoich próśb, które do nas kierujesz – ponieważ przetwarzanie jest niezbędne do zawarcia i realizacji umowy/umów
        (art. 6 ust. 1 lit. b RODO),</li>
    <li>świadczenia usług drogą elektroniczną i przesyłania informacji handlowych – na podstawie Twojej zgody (art.
        6 ust. 1 lit. a RODO w zw. z art. 10 ust. 2 ustawy o świadczeniu usług drogą elektroniczną),</li>
    <li>marketingu bezpośredniego produktów lub usług, w tym nawiązania kontaktu, przedstawiania oferty, prowadzenie
        pomiarów i analiz, obejmujących profilowanie (tj. korzystanie z danych osobowych do tworzenia Twojego profilu
        pod kątem produktów i usług, którymi możesz być zainteresowany), zapisywania danych w plikach cookies oraz
        gromadzenia danych ze stron www i aplikacji mobilnych – na podstawie Twojej zgody ( art. 6 ust. 1 lit. a RODO
        w zw. z art. 172 Prawo telekomunikacyjne),</li>
    <li>spełnienia ciążących na nas obowiązków prawnych wynikających z prawa Unii lub prawa polskiego (np. wobec Prezesa
        Urzędu Ochrony Konkurencji i Konsumentów, urzędów skarbowych) – ponieważ przetwarzanie jest niezbędne
        do wypełnienia wymogów prawnych, którym podlegamy (art.6 ust. 1 lit. c RODO),</li>
    <li>zapewnienia obsługi usług płatniczych, zapewnienia bezpieczeństwa usług, które świadczymy drogą elektroniczną,
        obsługi Twoich próśb przekazywanych np. poprzez formularz kontaktowy w sytuacji, gdy nie są one związane wprost
        z wykonaniem umowy, windykacja należności, prowadzenie postępowań sądowych, administracyjnych i mediacyjnych,
        przechowywanie danych dla celów archiwalnych oraz zapewnienie rozliczalności (spełnienia obowiązków wynikających
        z przepisów prawa) – ponieważ przetwarzanie jest niezbędne do realizacji naszego prawnie uzasadnionego interesu
        jakim jest obsługa świadczonych usług, realizacja Twoich próśb, możliwość ustalenia, dochodzenia lub obrony
        przed roszczeniami, archiwizacja danych (art. 6 ust. 1 lit. f RODO).</li>
</ul>
<p><strong>Aplikacja mobilna kierowcy</strong></p>
<p>Na potrzebę świadczenia usługi "Monitoring GPS przejazdu" zbierane są dokładne dane geolokalizacyjne (GPS) kierowcy.
    Dane gromadzone są od momentu zalogowania się przez kierowcę do konkretnego przejazdu w aplikacji, aż do momentu
    wylogowania się lub zakończenia przejazdu poprzez użycie opcji "Zakończ przejazd".</p>
<p>Poza danymi geolokalizacyjnymi moduł korzysta z danych "fitness" telefonu, w tym np. żyroskopu, jedynie w celu
    optymalizacji działania modułu zbierania danych geolokalizacyjnych, jak np. ruch urządzenia. W bazie danych
    gromadzone są tylko następujące dane: długość geograficzna, szerokość geograficzna, ID urządzenia, stan baterii
    urządzenia kierowcy, a także wybrane przez użytkownika/kierowcę obrazki lub pliki innego typu - tylko, gdy sam użyje
    ręcznie odpowiedniej do tego funkcji w aplikacji, celem ich przesłania. Dostęp do danych posiadają tylko strony
    zleconego przejazdu, a więc firma zlecająca przejazd (spedytor) oraz ten przejazd realizująca - przewoźnik.</p>
<p>Dane te gromadzone są tylko w celu umożliwienia bieżącego (na żywo) podglądu procesu realizacji przewozu przesyłki,
    jak i historycznych zrealizowanych przewozów.</p>
<p><strong>Newsletter</strong></p>
<p>Jeśli zapiszesz się do naszego newslettera, wówczas będziemy korzystać z danych niezbędnych do tego celu lub
    przekazanych nam przez Ciebie osobno w celu regularnego przesyłania naszego newslettera drogą elektroniczną na
    podstawie udzielonej przez Ciebie zgody.</p>
<p>Możesz w każdej chwili zrezygnować z otrzymywania newslettera, wysyłając nam wiadomość z stosowną informacją lub
    korzystając z odpowiedniego linka zamieszczonego w newsletterze. Po wyrejestrowaniu się usuniemy Twój adres e-mail,
    o ile nie wyrazisz w sposób wyraźny zgody na dalsze korzystanie z Twoich danych w innych celach lub nie zastrzeżemy
    sobie prawa dalszego korzystania z danych w ustawowo dozwolonych przypadkach.</p>
<p><strong>Komu i gdzie Administrator może przekazać Twoje dane?</strong></p>
<p>Do Twoich danych dostęp będą miały, poza pracownikami, pełnomocnikami i członkami organów Administratora, osoby i
    podmioty zapewniające działanie, utrzymanie i serwis systemów i rozwiązań IT stosowanych przez Administratora.
    Ponadto, w zależności od charakteru sprawy, Twoje dane osobowe Administrator może przekazać jedynie następującym
    podmiotom:</p>
<ul>
    <li>podmiotom współpracującym z Administratorem i realizującym na rzecz Administratora niezbędne w toku jego
        działalności usługi, w szczególności podmiotom świadczącym na rzecz Administratora usługi księgowe, rachunkowe,
        finansowe, doradztwa prawnego lub podatkowego - przy czym takie podmioty przetwarzają dane na podstawie umowy
        z administratorem i wyłącznie zgodnie z poleceniami administratora.</li>
    <li>organom publicznym, w tym sądom, na ich uzasadnione żądanie.</li>
</ul>
<p>W celu wykonania umowy przekazujemy Twoje dane firmie kurierskiej zajmującej się dostawą, o ile jest to niezbędne do
    doręczenia zamówionych towarów.</p>
<p>Zależnie od tego, którego dostawcę usług płatniczych wybierzesz w procesie zamówienia, celem realizacji płatności
    przekazujemy zgromadzone w tym celu dane płatnicze do instytucji kredytowej obsługującej płatność i ewentualnie do
    wybranego przez nas lub przez Ciebie dostawcy usług płatniczych. Niektórzy dostawcy usług płatniczych gromadzą dane
    samodzielnie, jeżeli założysz u nich konto. W takich przypadkach należy w ramach zamówienia zalogować się u dostawcy
    usług płatniczych podając swoje dane dostępowe. Obowiązuje wówczas również polityka prywatności danego dostawcy
    usług płatniczych.</p>
<p><strong>Zastosowanie Google (Universal) Analytics do celów analityki internetowej</strong></p>
<p>Serwis używa Google (Universal) Analytics, narzędzia do analityki internetowej firmy Google Inc. (www.google.com).
    Powyższe - w ramach analizy i oceny interesów - służy ochronie naszego prawnie uzasadnionego interesu, polegającego
    na optymalnej prezentacji naszej oferty. Google (Universal) Analytics korzysta z metod, które umożliwiają analizę
    korzystania przez Ciebie ze strony internetowej - na przykład pliki cookies. Automatycznie zgromadzone informacje
    dotyczące korzystania przez Ciebie z Serwisu są przekazywane z reguły do serwera Google w Stanach Zjednoczonych i
    tam przechowywane. Z uwagi na aktywowaną w Serwisie anonimizację IP, Twój adres IP jest skracany przed przekazaniem
    dalej w obrębie państw członkowskich Unii Europejskiej lub w innych państwach będących stroną Porozumienia o
    Europejskim Obszarze Gospodarczym. Tylko w wyjątkowych przypadkach pełny adres IP jest przekazywany do serwera
    Google w Stanach Zjednoczonych i tam skracany. Zanonimizowany adres IP przekazany przez Twoją przeglądarkę w ramach
    Google Analytics co do zasady nie jest łączony z innymi danymi Google. Google LLC ma siedzibę główną w USA i posiada
    certyfikat EU-US-Privacy Shield. Aktualny certyfikat dostępny jest <a href="https://www.privacyshield.gov/list"
        target="_blank"><strong>pod tym linkiem</strong></a>. W ramach umowy pomiędzy USA a Komisją Europejską ta
    ostatnia stwierdziła odpowiedni poziom ochrony danych w przypadku przedsiębiorstw posiadających certyfikat Privacy
    Shield. Możesz zapobiec rejestrowaniu zgromadzonych przez pliki cookies danych dotyczących korzystania przez Ciebie
    z naszej strony internetowej (w tym Twojego adresu IP) przez Google, jak również przetwarzaniu tych danych przez
    Google, jeśli pobierzesz i zainstalujesz wtyczkę do przeglądarki znajdującą się <a
        href="https://tools.google.com/dlpage/gaoptout?hl=pl" target="_blank"><strong>pod tym linkiem</strong></a>.
    Alternatywnie – zamiast instalacji wtyczki do przeglądarki internetowej możesz również kliknąć w <strong>niniejszy
        link</strong> aby wyłączyć gromadzenie danych przez Google Analytics Serwisie. Na Twoje urządzeniu końcowym
    zapisany zostanie wówczas plik cookie opt-out. Jeśli usuniesz pliki cookies, wówczas muszą Państwo ponownie kliknąć
    w powyższy link.</p>
<p><strong>Google AdWords Remarketing</strong></p>
<p>Z pomocą Google Adwords promujemy Serwis w wynikach wyszukiwania oraz na stronach osób trzecich. W tym celu przy
    okazji odwiedzin naszej strony internetowej automatycznie pozostawiany jest w urządzeniu każdego odwiedzającego tzw.
    plik Remarketing cookie firmy Google, który z pomocą pseudonimowego identyfikatora (ID) oraz na podstawie
    odwiedzanych przez Ciebie stron umożliwia wyświetlanie reklam opartych na zainteresowaniach. Powyższe - w ramach
    analizy i oceny interesów - służy ochronie naszego prawnie uzasadnionego interesu, polegającego na optymalnym
    działaniu naszej strony internetowej na rynku. Dalsze przetwarzanie danych ma miejsce tylko wtedy, gdy wyrazisz
    zgodę wobec Google na łączenie historii przeglądania i użycia aplikacji z Twoim kontem oraz wykorzystanie informacji
    z Twojego konta Google do personalizowania reklam, które wyświetlane są na stronach internetowych. Jeśli w takim
    przypadku będziesz zalogowani podczas wizyty na naszej stronie internetowej w serwisie Google, Google wykorzysta
    Twoje dane razem z danymi Google Analytics w celu stworzenia i zdefiniowania list grup docelowych dla celów
    remarketingu na różnych urządzeniach. W tym celu Google łączy tymczasowo Twoje dane osobowe z danymi Google
    Analytics, aby utworzyć grupy docelowe. Usługa Google AdWords Remarketing stanowi ofertę Google LLC (www.google.pl).
    Google LLC ma siedzibę główną w USA i posiada certyfikat EU-US-Privacy Shield. Aktualny certyfikat dostępny jest <a
        href="https://www.privacyshield.gov/list" target="_blank"><strong>pod podanym linkiem</strong></a>. W ramach
    umowy pomiędzy USA a Komisją Europejską ta ostatnia stwierdziła odpowiedni poziom ochrony danych w przypadku
    przedsiębiorstw posiadających certyfikat Privacy Shield. Możesz dezaktywować cookies wykorzystywane do remarketingu
    klikając w niniejszy <a
        href="http://www.google.com/settings/ads/anonymous?hl=de&amp;sig=ACi0TCgl4UGsZh61Q3WxLmZrwxpLhi5Brsz7n4oppCNx_HUc_H_jSUofKIBlIzSQqHsOOw9rIWpi-60gYxrsLKPSD2V09YE1Q2b3WEVPY6wxNgvNS-cammA"
        target="_blank"><strong>link</strong></a>. Ponadto możesz zasięgnąć informacji na temat stosowania cookies i
    odpowiednich ustawień na stronie <a href="http://www.aboutads.info/" target="_blank"><strong>Digital Advertising
            Alliance</strong></a>.</p>
<p><strong>Przekazywanie danych poza Europejski Obszar Gospodarczy lub do organizacji międzynarodowych</strong></p>
<p>Poza wyżej opisanymi przypadkami, Twoje dane osobowe nie będą przekazywane poza terytorium Europejskiego Obszaru
    Gospodarczego ani do organizacji międzynarodowych.</p>
<p><strong>Jak długo Administrator będzie przechowywać Twoje dane? </strong></p>
<p><strong>(informacje o okresie przechowania danych)</strong></p>
<p>10 lat.</p>
<p><strong>Okres przechowywania Twoich danych przez Administratora będzie zależał od celu przetwarzania Twoich
        danych.</strong></p>
<p>W przypadku, w którym Twoje dane są przetwarzane przez Administratora w celu wykonania umowy pomiędzy Tobą a
    Administratora lub w celu wykonania innych zobowiązań na Twoją rzecz przez Administratora (np. do prowadzenia konta
    klienta), Twoje dane osobowe Administrator będzie przechowywał przez okres potrzebny do wykonania zobowiązań
    Administrator na Twoją rzecz, np. wynikających z umowy sprzedaży oraz do upływu okresu przedawnienia wszelkich
    Twoich roszczeń wobec Administrator i roszczeń Administrator wobec Ciebie, związanych z takimi zobowiązaniami, np.
    wynikających z umowy sprzedaży, chyba że przechowywanie danych będzie konieczne dłużej dla celów zobowiązań
    podatkowych – w takim wypadku usunięcie nastąpi niezwłocznie po upływie okresu przedawnienia zobowiązań podatkowych
    związanych z zobowiązaniem, np. z zawartą umową (np. w celach rozliczeniowych związanych z daną umową, Twoje dane
    będą przechowywane przez 5 lat licząc od końca roku kalendarzowego, w którym upłynął termin płatności podatku
    związanego z umową).</p>
<p>W przypadku, w którym Twoje dane są przetwarzane przez Administratora z uwagi na niezbędność przetwarzania do celów
    wynikających z prawnie uzasadnionych interesów Administratora, Twoje dane osobowe Administrator będzie przechowywał
    przez okres niezbędny do zrealizowania celów wynikających z Takich interesów, przy czym dla celu polegającego na
    marketingu bezpośrednim przez okres istnienia prawnie uzasadnionego interesu do prowadzenia marketingu, chyba że
    skorzystasz ze swojego prawa i zgłosisz sprzeciw wobec przetwarzania tych danych dla celów marketingowych. W takim
    przypadku Administrator może przechowywać Twoje dane przez okres przedawnienia Twoich roszczeń wobec Administratora
    lub roszczeń Administratora wobec Ciebie, wynikający z przepisów prawa, jeśli przetwarzanie tych danych będzie
    niezbędne w celu ustalenia lub dochodzenia roszczeń, a także w celu obrony przed takimi roszczeniami.</p>
<p>Dla realizacji prawnie uzasadnionych interesów Administratora obejmujących ustalenie i dochodzenie roszczeń lub
    obronę przed roszczeniami, Administrator może przechowywać Twoje dane przez okres przedawnienia Twoich roszczeń
    wobec Administratora lub roszczeń Administratora wobec Ciebie wynikający z przepisów prawa (przykładowo ogólny
    termin przedawnienia roszczeń związanych z działalnością gospodarczą wynosi 3 lata, zaś ogólny termin przedawnienia
    roszczeń konsumenta wobec Administratora wynosi 10 lat; podane wyżej okresy przechowywania danych osobowych mogą
    ulec zmianie wraz ze zmianą powszechnie obowiązujących przepisów prawa).</p>
<p>Dla realizacji prawnie uzasadnionych interesów Administratora obejmujących udzielenie odpowiedzi na Twoją wiadomość,
    pytanie, skargę lub sugestię, Administrator może przechowywać Twoje dane przez okres niezbędny do udzielenia
    odpowiedzi lub załatwienia sprawy, z którą się zwrócisz do Administrator, przy czym Administrator może przedłużyć
    ten okres o okres przedawnienia Twoich roszczeń wobec Administratora lub roszczeń Administratora wobec Ciebie
    wynikający z przepisów prawa, jeśli przetwarzanie tych danych będzie niezbędne w celu ustalenia lub dochodzenia
    roszczeń, a także w celu obrony przed takimi roszczeniami.</p>
<p>Ponadto, w przypadku skierowania roszczeń w stosunku do Administratora lub przez Administratora, np. w związku z
    umową sprzedaży zawartą pomiędzy Tobą a Administratorem, Twoje dane będą przechowywane nie dłużej niż będzie to
    potrzebne w związku z właściwymi postępowaniami dotyczącymi takich roszczeń.</p>
<p>W przypadku, gdy Twoje dane są przetwarzane przez Administratora z uwagi na ciążący na Administratorze obowiązek
    prawny, Administrator będzie przechowywał te dane tak długo jak długo ciążył będzie na Administratorze właściwy
    obowiązek prawny. Jeżeli Twoje dane będą konieczne do dokumentowania zdarzeń gospodarczych dla celów rachunkowych,
    Twoje dane w tym zakresie będą przechowywane przez Administratora tak długo jak długo będzie istniał prawny
    obowiązek Administratora posiadania dokumentu tych zdarzeń.</p>
<p><strong>Informacje o przysługujących Ci prawach </strong></p>
<p><strong>Prawo dostępu do danych</strong></p>
<p>Masz prawo do żądania dostępu do Twoich danych osobowych, w tym w szczególności informacji o tym czy Administrator
    przetwarza Twoje dane osobowe oraz o zakresie danych posiadanych przez Administratora, celach przetwarzania danych
    czy kategoriach odbiorców Twoich danych, o ile to możliwe, o planowanym okresie przechowywania danych, o
    przysługujących Ci prawach dotyczących danych osobowych, informacje o źródłach pozyskania przez Administratora
    Twoich danych, jeżeli nie zostały zebrane od Ciebie. Masz ponadto prawo do uzyskania kopii danych, z tym
    zastrzeżeniem, że uzyskanie pierwszej kopii danych jest bezpłatne a uzyskanie każdej kolejne kopii może wiązać się z
    koniecznością wniesienia opłaty w rozsądnej wysokości uwzględniającej koszty administracyjne przygotowania takiej
    kopii danych.</p>
<p><strong>Prawo do sprostowania danych</strong></p>
<p>Masz prawo żądania niezwłocznego sprostowania nieprawidłowych danych lub, z uwzględnieniem celów przetwarzania,
    uzupełnienia danych niekompletnych.</p>
<p><strong>Prawo do usunięcia danych (prawo do bycia zapomnianym)</strong></p>
<p>Masz prawo żądania usunięcia Twoich danych, jeżeli zachodzi jedna z następujących okoliczności:</p>
<ul>
    <li>dane osobowe nie są już niezbędne do celów, w których zostały zebrane lub w inny sposób przetwarzane;</li>
    <li>wniosłaś/wniosłeś skuteczny sprzeciw wobec przetwarzania;</li>
    <li>dane osobowe były przetwarzane niezgodnie z prawem;</li>
    <li>dane osobowe muszą zostać usunięte w celu wywiązania się z obowiązku prawnego;</li>
    <li>cofnęłaś/cofnąłeś zgodę na przetwarzanie danych osobowych a dane osobowe były przetwarzane na podstawie Twojej
        zgody i nie ma innej podstawy prawnej ich przetwarzania.</li>
</ul>
<p>Nie będziesz mogła/mógł jednak skorzystać z prawa do usunięcia danych m.in. jeżeli takie dane niezbędne są do
    ustalenia lub dochodzenia roszczeń lub obrony przed roszczeniami.</p>
<p><strong>Prawo do ograniczenia przetwarzania danych</strong></p>
<p>Masz prawo żądania ograniczenia przetwarzania w następujących przypadkach: </p>
<ul>
    <li>jeżeli kwestionujesz prawidłowość danych osobowych – na okres pozwalający sprawdzić prawidłowość tych danych
    </li>
    <li>przetwarzanie jest niezgodne z prawem i sprzeciwiasz się usunięciu danych osobowych, żądając w zamian
        ograniczenia ich wykorzystywania; </li>
    <li>dane osobowe nie są już potrzebne Administratorowi ale potrzebne są Tobie do ustalenia, dochodzenia lub obrony
        przed roszczeniami;</li>
    <li>w przypadku wniesienia przez Ciebie sprzeciwu – do czasu stwierdzenia, czy prawnie uzasadnione podstawy po
        stronie Administratora są nadrzędne wobec podstaw Twojego sprzeciwu.</li>
</ul>
<p><strong>Prawo do przenoszenia danych</strong></p>
<p>Masz prawo otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego Swoje
    dane osobowe, które dostarczyłaś/dostarczyłeś Administratorowi oraz masz prawo przesłać te dane osobowe innemu
    administratorowi bez przeszkód ze strony Administratora, jeżeli: </p>
<ul>
    <li>przetwarzanie odbywa się na podstawie Twojej zgody lub na podstawie umowy z Tobą a jednocześnie;</li>
    <li>przetwarzanie odbywa się w sposób zautomatyzowany. </li>
</ul>
<p>Masz jednocześnie prawo żądania by dane osobowe zostały przesłane przez Administratora bezpośrednio innemu
    administratorowi danych, o ile jest to technicznie możliwe.</p>
<p><strong>Prawo do wniesienia sprzeciwu wobec przetwarzania danych</strong></p>
<p>Masz prawo w dowolnym momencie wnieść sprzeciw – z przyczyn związanych ze swoją szczególną sytuacją – wobec
    przetwarzania Twoich danych, jeżeli podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora.
</p>
<p>Na skutek sprzeciwu Administrator zaprzestanie przetwarzać Twoje dane osobowe, chyba że wykaże istnienie ważnych
    prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Twoich interesów, praw i wolności lub podstaw do
    ustalenia, dochodzenia roszczeń lub obrony przed roszczeniami.</p>
<p>Jeżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, możesz w dowolnym momencie wnieść sprzeciw
    wobec takiego przetwarzania, w tym profilowania, bez konieczności wykazywania przyczyn związanych ze swoją
    szczególną sytuacją a Administrator zobowiązany jest niezwłocznie zaprzestać takiego przetwarzania.</p>
<p><strong>Prawo wniesienia skargi do organu nadzorczego</strong></p>
<p>Masz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych jeżeli sądzisz, że przetwarzanie Twoich
    danych osobowych narusza przepisy prawa w tym zakresie.</p>
<p><strong>Informacja czy podanie danych osobowych jest wymogiem ustawowym lub umownym lub warunkiem zawarcia umowy oraz
        o braku zobowiązania do podania danych</strong></p>
<p>Wszelkie dane podajesz dobrowolnie.</p>
<p>W zależności od sytuacji, w której Administrator może pozyskać Twoje dane, podanie pewnych danych (określonych przez
    Administratora jako dane wymagane) może być warunkiem zawarcia umowy z Administratorem lub warunkiem rozpoczęcia
    świadczenia usług przez Administrator, np. otwarcia i prowadzenia przez konta klienta.</p>
<p>Podanie danych adresu jest niezbędne np. w celu zamówienia oferty. Podanie telefonu może być niezbędne jest do
    skorzystania z dodatkowych usług polegających np. na umówieniu spotkania. Podanie imienia i nazwiska (nazwy), adresu
    i numeru NIP może stanowić ustawowy wymóg i być niezbędne do przygotowania dokumentacji dla celów podatkowych. </p>
<p>W przypadku kierowania wiadomości lub próśb do Administratora (np. o przesłanie oferty), podanie danych
    teleadresowych nie jest wymogiem ustawowym ani umownym ani warunkiem zawarcia umowy, jednakże podanie wymaganych
    danych jest niezbędne dla umożliwienia Administratora udzielenia odpowiedzi.</p>
<p><strong>Informacja o konsekwencjach niepodania danych</strong></p>
<p>W zależności od sytuacji, w której Administrator może pozyskać Twoje dane, brak podania określonych informacji może
    uniemożliwić zawarcie umowy z Administratorem, skorzystanie z dodatkowych usług, otwarcie i prowadzenie Twojego
    konta klienta, otrzymanie prawidłowych dokumentów podatkowych od Administratora, otrzymanie od Administratora oferty
    lub odpowiedzi na wiadomość skierowaną do Administratora, złożenie do Administratora reklamacji, skargi, interwencji
    czy zmiany dyspozycji zamówienia.</p>
<p><strong>Informacja o wykorzystywaniu danych do zautomatyzowanego podejmowania decyzji, </strong></p>
<p><strong>w tym do profilowania</strong></p>
<p>Twoje dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym do profilowania.</p>
<p><strong>Co to są cookies i jak ich używamy</strong></p>
<p>Pliki cookies (tzw. „ciasteczka”) to dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w
    Twoim urządzeniu końcowym (np. komputerze). Cookies zazwyczaj zawierają nazwę strony internetowej, z której
    pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer. Pliki cookies wykorzystywane są
    w celu:</p>
<ul>
    <li>dostosowania zawartości Serwisu do preferencji użytkownika oraz optymalizacji korzystania ze stron
        internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie użytkownika Serwisu i odpowiednio
        wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;</li>
    <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy Serwisu korzystają ze stron
        internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
    <li>utrzymania sesji użytkownika Serwisu.</li>
</ul>
<p>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</p>
<ul>
    <li>„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające
        pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu; pliki cookies służące
        do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach
        Serwisu;</li>
    <li>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych
        Serwisu;</li>
    <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację
        interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru
        czcionki, wyglądu strony internetowej itp.;</li>
    <li>„reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych
        do ich zainteresowań.</li>
</ul>
<p>Przeglądarki internetowe (Twoje oprogramowanie) domyślnie dopuszczają przechowywanie w urządzeniu końcowym plików
    cookies. Możesz zawsze zmienić ustawienia przeglądarki internetowej decydujące o użyciu plików cookies. Ograniczenia
    stosowania plików cookies mogą wpłynąć na funkcjonalność Serwisu. Pliki cookies zamieszczane w Twoim urządzeniu
    końcowym mogą być wykorzystywane również przez współpracujących z Administratorem reklamodawców oraz partnerów.
    Informujemy, iż podmiotem odpowiedzialnym za zamieszczanie plików cookies na Twoim urządzeniu końcowym jednocześnie
    posiadającym do nich dostęp jest Administrator. Informacje o sposobach obsługi plików cookies dostępne są
    w ustawieniach oprogramowania (przeglądarki internetowej). Więcej informacji o plikach cookies dostępnych jest np.
    tutaj <a href="http://wszystkoociasteczkach.pl">http://wszystkoociasteczkach.pl</a>.</p>
<p>W menu pomocniczym przeglądarki internetowej znajdziesz wyjaśnienia dotyczące zmiany ustawień cookies. Dostępne są
    one pod następującymi linkami:</p>
<p><br />Internet Explorer™:
    https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies<br />Safari™:
    http://safari.helpmax.net/pl/ochrona-i-prywatnosc/usuwanie-plikow-cookie/<br />Chrome™:
    https://support.google.com/chrome/answer/95647?hl=pl&amp;hlrm=en<br />Firefox™:
    https://support.mozilla.org/pl/kb/usuwanie-ciasteczek<br />Opera™:
    http://help.opera.com/Windows/12.10/pl/cookies.html<br /><br />W przypadku braku zgody na wykorzystywanie plików
    cookies funkcjonalność Serwisu może zostać ograniczona.</p>
    </div>
)