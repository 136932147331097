import React from 'react'

export default () => (
    <div>
        <h1>DATENSCHUTZ- UND COOKIE-POLITIK</h1>
<p>Im Hinblick auf das Inkrafttreten am 25. Mai 2018. Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates
    vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien
    Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (ABl . EU L 119 s.1), der so genannten RODO - verpflichten
    wir uns, Ihr Recht auf Privatsphäre im Internet zu respektieren und alle Anstrengungen zu unternehmen, um die von
    Ihnen bereitgestellten personenbezogenen Daten ordnungsgemäß zu schützen. Gleichzeitig weisen wir darauf hin, dass
    die Website <a href="http://www.kontenerowy.pl/">www.kontenerowy.pl</a> (der <strong>"Dienst</strong>") ohne Angabe
    Ihrer persönlichen Daten besucht werden kann.</p>
<h1>Verwalter der personenbezogenen Daten</h1>
<p>Der Verwalter Ihrer personenbezogenen Daten (<strong>"Verwalter</strong>") ist RR Media spółka z ograniczoną
    odpowiedzialnością mit Sitz in Gdynia (81-339) an der ul. Polska 19, E-Mail <a
        href="mailto:biuro@e-containers.eu">biuro@e-containers.eu,</a> Tel. +48 58 622 39 01.</p>
<h1>Datenschutzbeauftragter</h1>
<p>Der für die Verarbeitung Verantwortliche (Verwalter) hat keinen Datenschutzbeauftragten ernannt. Wir verpflichten uns
    jedoch, geeignete Maßnahmen zum Schutz vor unbefugtem Zugriff oder Weitergabe Ihrer Daten an Dritte zu treffen.
    Unsere Datenbanken sind vor dem Zugriff Dritter geschützt. Sollten Sie Bedenken hinsichtlich der Verarbeitung Ihrer
    Daten haben, zögern Sie bitte nicht, sich unter den oben angegebenen Kontaktdaten an den Administrator zu wenden.
</p>
<h1>Ziele und Rechtsgrundlage der Datenverarbeitung, berechtigtes Interesse an der Verarbeitung</h1>
<p>Im Folgenden geben wir Ihnen einen Überblick über alle Datenverarbeitungszwecke, die im Rahmen der Geschäftstätigkeit
    des Verwalters standardmäßig vorkommen. Der spezifische Zweck der Verarbeitung Ihrer Daten wird Ihnen jedes Mal von
    dem für die Verarbeitung Verantwortlichen zum Zeitpunkt der Erfassung Ihrer Daten mitgeteilt. Ihre personenbezogenen
    Daten können daher zu folgenden Zwecken verarbeitet werden:</p>
<ul>
    <li>den/die Vertrag/Verträge abzuschließen und zu erfüllen, einschließlich der Abwicklung von Transaktionen, der
        Lösung von Problemen und der Erfüllung Ihrer Anfragen an uns, da die Verarbeitung für den Abschluss und die
        Erfüllung des/der Vertrags/Verträge erforderlich ist (Artikel 6 Absatz 1 Buchstabe b der RODO/DSGVO),</li>
    <li>die Bereitstellung elektronischer Dienste und die Zusendung kommerzieller Informationen - auf der Grundlage
        Ihrer Einwilligung (Art. 6(1)(a) RODO i.V.m. dem Artikel 10(2) des Gesetzes über die Erbringung elektronischer
        Dienstleistungen),</li>
    <li>Direktmarketing von Produkten oder Dienstleistungen, einschließlich der Kontaktaufnahme, der Präsentation eines
        Angebots, der Durchführung von Umfragen und Analysen, einschließlich der Profilerstellung (d. h. der Verwendung
        personenbezogener Daten zur Erstellung eines Profils von Ihnen in Bezug auf Produkte und Dienstleistungen, an
        denen Sie interessiert sein könnten), der Speicherung von Daten in Cookies und der Erfassung von Daten von
        Websites und mobilen Anwendungen - auf der Grundlage Ihrer Einwilligung (Artikel 6 Absatz 1 Buchstabe a RODO in
        Verbindung mit Artikel 172 Telekommunikationsgesetz),</li>
    <li>zur Erfüllung unserer rechtlichen Verpflichtungen nach EU- oder polnischem Recht (z.B. gegenüber dem Präsidenten
        des Amtes für Wettbewerb und Verbraucherschutz, Steuerbehörden) - da die Verarbeitung für die Erfüllung der
        gesetzlichen Anforderungen, denen wir unterliegen, erforderlich ist (Art. 6(1)(c) RODO),</li>
    <li>zur Erbringung von Zahlungsdiensten, zur Gewährleistung der Sicherheit der von uns elektronisch erbrachten
        Dienstleistungen, zur Bearbeitung Ihrer Anfragen, z. B. über ein Kontaktformular, sofern diese nicht unmittelbar
        mit der Erfüllung eines Vertrags zusammenhängen, zum Einzug von Forderungen, zur Durchführung von Gerichts-,
        Verwaltungs- und Vermittlungsverfahren, zur Speicherung von Daten für Archivierungszwecke und zur Gewährleistung
        der</li>
</ul>
<p>Rechenschaftspflicht (Erfüllung rechtlicher Verpflichtungen) - da die Verarbeitung für unsere berechtigten Interessen
    erforderlich ist, wie z. B. das Funktionieren der angebotenen Dienste, die Erfüllung Ihrer Anfragen, die
    Möglichkeit, Ansprüche zu begründen, geltend zu machen oder abzuwehren, die Archivierung von Daten (Artikel 6 Absatz
    1 Buchstabe f) RODO).</p>
<h1>Newsletter</h1>
<p>Wenn Sie unseren Newsletter abonnieren, verwenden wir die dafür erforderlichen oder uns von Ihnen gesondert
    mitgeteilten Daten zum Zweck der regelmäßigen elektronischen Zusendung unseres Newsletters auf Grundlage Ihrer
    Einwilligung.</p>
<p>Sie können den Newsletter jederzeit wieder abbestellen, indem Sie uns eine entsprechende Nachricht schicken oder den
    entsprechenden Link im Newsletter nutzen. Nach Ihrer Abmeldung werden wir Ihre E-Mail-Adresse löschen, es sei denn,
    Sie stimmen der weiteren Nutzung Ihrer Daten für andere Zwecke ausdrücklich zu oder wir behalten uns vor, Ihre Daten
    in gesetzlich zulässigen Fällen weiter zu nutzen.</p>
<h1>An wen und wohin kann der Administrator Ihre Daten übermitteln?</h1>
<p>Neben den Mitarbeitern, Beauftragten und Organmitgliedern des Verwalters haben auch Personen und Subjekte Zugang zu
    Ihren Daten, die den Betrieb, die Wartung und die Instandhaltung der vom Verwalter verwendeten Systeme und
    IT-Lösungen sicherstellen. Darüber hinaus dürfen Ihre personenbezogenen Daten je nach Art des Falles vom Verwalter
    nur an die folgenden Stellen übermittelt werden:</p>
<ul>
    <li>Subjekte, die mit dem Verwalter zusammenarbeiten und Dienstleistungen erbringen, die für den Verwalter im Rahmen
        seiner Tätigkeit erforderlich sind, insbesondere Subjekte, die für den Verwalter Buchhaltungs-, Finanz-, Rechts-
        oder Steuerberatungsdienstleistungen erbringen, wobei diese Subjekte Daten auf der Grundlage einer Vereinbarung
        mit dem Verwalter und nur gemäß den Anweisungen des Verwalters verarbeiten,</li>
    <li>Behörden, einschließlich der Gerichte, auf deren begründeten Antrag.</li>
</ul>
<p>Zur Vertragserfüllung geben wir Ihre Daten an das mit der Lieferung beauftragte Versandunternehmen weiter, soweit
    dies zur Lieferung der Waren notwendig ist.</p>
<p>Je nachdem, welchen Zahlungsdienstleister Sie während des Bestellvorgangs auswählen, geben wir zur Abwicklung Ihrer
    Zahlung die zu diesem Zweck erhobenen Zahlungsdaten an das die Zahlung abwickelnde Kreditinstitut und ggf. an den
    von uns oder von Ihnen ausgewählten Zahlungsdienstleister weiter. Einige Zahlungsdienstleister erheben selbst Daten,
    wenn Sie ein Konto bei ihnen einrichten. In diesen Fällen müssen Sie sich im Rahmen Ihrer Bestellung bei Ihrem
    Zahlungsanbieter mit Ihren Zugangsdaten anmelden. Es gelten dann auch die Datenschutzbestimmungen des jeweiligen
    Zahlungsdienstleisters.</p>
<h1>Verwendung von Google (Universal) Analytics für Webanalysezwecke</h1>
<p>Die Website benutzt Google (Universal) Analytics, ein Webanalysetool der Google Inc. (www.google.com). Dies dient -
    im Rahmen der Interessenanalyse und -abwägung - der Wahrung unseres berechtigten Interesses, unser Angebot optimal
    zu präsentieren. Google (Universal) Analytics verwendet Methoden, die eine Analyse Ihrer Nutzung der Website
    ermöglichen - zum Beispiel Cookies. Automatisch erfasste Informationen über Ihre Nutzung der Website werden in der
    Regel an einen Google-Server in den Vereinigten Staaten übertragen und dort gespeichert. Aufgrund der auf der
    Website aktivierten IP-Anonymisierung wird Ihre IP-Adresse gekürzt, bevor sie innerhalb der Mitgliedstaaten der
    Europäischen Union oder anderer Vertragsstaaten des Abkommens </p>
<p>über den Europäischen Wirtschaftsraum weitergeleitet wird. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
    Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser
    übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht mit anderen Daten von Google zusammengeführt. Google
    LLC hat seinen Hauptsitz in den USA und ist vom EU-US-Privacy Shield zertifiziert. Das aktuelle Zertifikat finden
    Sie <strong>unter diesem Link</strong>. Im Rahmen des Abkommens zwischen den USA und der Europäischen Kommission hat
    letztere ein angemessenes Datenschutzniveau für Unternehmen mit Privacy-Shield-Zertifizierung festgelegt. Sie können
    die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
    IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das <strong>unter
        diesem Link</strong>verfügbare Browser-Plugin herunterladen und installieren. Alternativ zur Installation des
    Browser-Plug-ins können Sie auch auf <strong>diesen </strong>Link klicken, um die Datenerfassung durch Google
    Analytics auf der Website zu deaktivieren. Ein Opt-out-Cookie wird dann auf Ihrem Endgerät gespeichert. Löschen Sie
    Ihre Cookies, müssen Sie den obigen Link erneut anklicken.</p>
<h1>Google AdWords Remarketing</h1>
<p>Mit Hilfe von Google Adwords bewerben wir die Website in den Suchergebnissen und auf Websites Dritter. Zu diesem
    Zweck wird beim Besuch unserer Website auf dem Gerät jedes Besuchers automatisch ein so genannter Google
    Remarketing-Cookie abgelegt, der mit Hilfe einer pseudonymen Kennung (ID) und auf Basis der von Ihnen besuchten
    Seiten die Anzeige von interessenbezogener Werbung ermöglicht. Dies dient - im Rahmen der Interessenanalyse und
    -bewertung - der Wahrung unseres berechtigten Interesses an der Optimierung der Marktleistung unserer Website. Eine
    weitere Datenverarbeitung findet nur statt, wenn Sie damit einverstanden sind, dass Google Ihren Browserverlauf und
    Ihre Anwendungsnutzung mit Ihrem Konto verknüpft und Informationen aus Ihrem Google-Konto zur Personalisierung der
    auf den Websites angezeigten Anzeigen verwendet. Wenn Sie dann während Ihres Besuchs auf unserer Website bei Google
    eingeloggt sind, wird Google Ihre Daten zusammen mit Google Analytics-Daten verwenden, um Zielgruppenlisten für
    Remarketing-Zwecke auf verschiedenen Geräten zu erstellen und zu definieren. Zu diesem Zweck kombiniert Google
    vorübergehend Ihre persönlichen Daten mit Google Analytics-Daten, um Zielgruppen zu erstellen. Der Google AdWords
    Remarketing-Dienst ist ein Angebot von Google LLC (www.google.pl). Google LLC hat seinen Hauptsitz in den USA und
    ist vom EU-US-Privacy Shield zertifiziert. Das aktuelle Zertifikat finden Sie unter dem <strong>angegebenen
        Link</strong>. Im Rahmen des Abkommens zwischen den USA und der Europäischen Kommission hat letztere ein
    angemessenes Datenschutzniveau für Unternehmen mit Privacy-Shield-Zertifizierung festgelegt. Sie können die für das
    Remarketing verwendeten Cookies deaktivieren, indem Sie auf diesen <strong>Link</strong>klicken. Darüber hinaus
    können Sie auf der Website der <strong>Digital Advertising Alliance</strong>mehr über die Verwendung von Cookies und
    die entsprechenden Einstellungen erfahren.</p>
<h1>Übermittlung von Daten außerhalb des Europäischen Wirtschaftsraums oder an internationale Organisationen</h1>
<p>Außer in den oben beschriebenen Fällen werden Ihre personenbezogenen Daten nicht außerhalb des Europäischen
    Wirtschaftsraums oder an internationale Organisationen übermittelt.</p>
<h1>Wie lange wird der Administrator Ihre Daten aufbewahren? (Informationen über die Dauer der Speicherung der Daten)
</h1>
<p>Der Zeitraum, für den der für die Verarbeitung Verantwortliche Ihre Daten aufbewahrt, hängt vom Zweck der
    Verarbeitung Ihrer Daten ab.</p>
<p>Wenn Ihre Daten vom Verwalter zur Erfüllung eines Vertrags zwischen Ihnen und dem Verwalter oder zur Erfüllung
    anderer Verpflichtungen in Ihrem Namen durch den Verwalter (z. B. zur Führung eines Kundenkontos) verarbeitet
    werden, werden Ihre</p>
<p>personenbezogenen Daten so lange von dem Verwalter aufbewahrt, wie es für die Erfüllung der Verpflichtungen des
    Verwalters Ihnen gegenüber, z. B. aus dem Kaufvertrag, erforderlich ist, und bis zum Ablauf der Verjährungsfrist für
    alle Ihre Ansprüche gegenüber dem Verwalter und die Ansprüche des Verwalters gegenüber Ihnen im Zusammenhang mit
    diesen Verpflichtungen, z. B. die sich aus einem Kaufvertrag ergeben, es sei denn, die Speicherung der Daten ist für
    die Erfüllung steuerlicher Pflichten länger erforderlich; in diesem Fall erfolgt die Löschung unverzüglich nach
    Ablauf der Verjährungsfrist für steuerliche Pflichten im Zusammenhang mit der Verpflichtung, z. B. dem
    abgeschlossenen Vertrag (z. B. für Abrechnungszwecke im Zusammenhang mit einem bestimmten Vertrag werden Ihre Daten
    5 Jahre lang gespeichert, gerechnet ab dem Ende des Kalenderjahres, in dem die Frist für die Zahlung von Steuern im
    Zusammenhang mit dem Vertrag abgelaufen ist).</p>
<p>Werden Ihre Daten von dem für die Verarbeitung Verantwortlichen verarbeitet, weil die Verarbeitung für Zwecke
    erforderlich ist, die sich aus den berechtigten Interessen des für die Verarbeitung Verantwortlichen ergeben, so
    bewahrt der für die Verarbeitung Verantwortliche Ihre personenbezogenen Daten so lange auf, wie es für die Erfüllung
    der sich aus diesen Interessen ergebenden Zwecke erforderlich ist, d. h. für die Zwecke des Direktmarketings für die
    Dauer des berechtigten Interesses an der Durchführung des Marketings, es sei denn, Sie machen von Ihrem Recht
    Gebrauch und erheben Widerspruch gegen die Verarbeitung dieser Daten zu Marketingzwecken. In diesem Fall kann der
    Verwalter Ihre Daten für die Dauer der Verjährung Ihrer Ansprüche gegen den Verwalter oder der Ansprüche des
    Verwalters gegen Sie nach dem Gesetz aufbewahren, wenn die Verarbeitung dieser Daten für die Feststellung oder
    Geltendmachung von Ansprüchen sowie für die Verteidigung gegen solche Ansprüche erforderlich ist.</p>
<p>Zur Verfolgung der berechtigten Interessen des Verwalters an der Feststellung und Geltendmachung von Ansprüchen oder
    der Abwehr von Ansprüchen kann der Verwalter Ihre Daten für die Dauer der Verjährung Ihrer Ansprüche gegen den
    Verwalter oder der Ansprüche des Verwalters gegen Sie nach dem Gesetz aufbewahren (z.B. beträgt die allgemeine
    Verjährungsfrist für Ansprüche im geschäftlichen Bereich 3 Jahre, während die allgemeine Verjährungsfrist für
    Ansprüche von Verbrauchern gegen den Verwalter 10 Jahre beträgt; die vorgenannten Aufbewahrungsfristen für
    personenbezogene Daten können sich bei Änderungen der allgemein geltenden Gesetze ändern).</p>
<p>Zur Verfolgung der berechtigten Interessen des Verwalters an der Beantwortung Ihrer Nachricht, Frage, Reklamation
    oder Anregung kann der Verwalter Ihre Daten für den Zeitraum speichern, der für die Beantwortung oder Lösung der
    Angelegenheit, mit der Sie sich an den Verwalter gewandt haben, erforderlich ist, wobei der Verwalter diese Frist um
    die Verjährungsfrist Ihrer Ansprüche gegen den Verwalter oder der gesetzlichen Ansprüche des Verwalters gegen Sie
    verlängern kann, wenn die Verarbeitung dieser Daten für die Begründung oder Geltendmachung von Ansprüchen sowie für
    die Abwehr solcher Ansprüche erforderlich ist.</p>
<p>Darüber hinaus werden Ihre Daten für den Fall, dass Ansprüche gegen den Verwalter oder vom Verwalter geltend gemacht
    werden, z.B. im Zusammenhang mit einem zwischen Ihnen und dem Verwalter abgeschlossenen Kaufvertrag, nicht länger
    gespeichert, als es im Zusammenhang mit dem jeweiligen Verfahren über solche Ansprüche erforderlich ist.</p>
<p>Werden Ihre Daten von dem für die Verarbeitung Verantwortlichen aufgrund einer gesetzlichen Verpflichtung
    verarbeitet, so bewahrt der für die Verarbeitung Verantwortliche die Daten so lange auf, wie die entsprechende
    gesetzliche Verpflichtung für den für die Verarbeitung Verantwortlichen in Kraft ist. Wenn Ihre Daten für die
    Dokumentation von Geschäftsvorfällen zu Buchhaltungszwecken erforderlich sind, werden Ihre diesbezüglichen Daten vom
    Verwalter so lange aufbewahrt, wie für den Verwalter eine gesetzliche Verpflichtung besteht, diese Vorgänge zu
    dokumentieren.</p>
<h1>Informationen über Ihre Rechte</h1>
<p><strong>Recht auf Zugang zu den Daten</strong></p>
<p>Sie haben das Recht, Auskunft über Ihre personenbezogenen Daten zu verlangen, insbesondere darüber, ob der Verwalter
    Ihre personenbezogenen Daten verarbeitet und in welchem Umfang der </p>
<p>Verwalter über diese Daten verfügt, über die Zwecke der Verarbeitung oder die Kategorien von Empfängern Ihrer Daten,
    soweit möglich, über die vorgesehene Dauer der Speicherung der Daten, über Ihre Rechte in Bezug auf Ihre
    personenbezogenen Daten, über die Quellen Ihrer Daten, die der Verwalter erhalten hat, wenn sie nicht bei Ihnen
    erhoben wurden. Sie haben außerdem das Recht, eine Kopie der Daten zu erhalten, wobei die erste Kopie der Daten
    kostenlos ist und für jede weitere Kopie eine angemessene Gebühr erhoben werden kann, die die Verwaltungskosten für
    die Erstellung dieser Kopie der Daten berücksichtigt.</p>
<h1>Recht auf Berichtigung der Daten</h1>
<p>Sie haben das Recht, die unverzügliche Berichtigung unrichtiger Daten oder, unter Berücksichtigung der Zwecke der
    Verarbeitung, die Vervollständigung unvollständiger Daten zu verlangen.</p>
<h1>Recht auf Löschung der Daten (Recht auf Vergessenwerden)</h1>
<p>Sie haben das Recht, die Löschung Ihrer Daten zu verlangen, wenn einer der folgenden Umstände vorliegt:</p>
<ul>
    <li>die personenbezogenen Daten für die Zwecke, für die sie erhoben oder anderweitig verarbeitet wurden, nicht mehr
        erforderlich sind;</li>
    <li>sie haben einen wirksamen Widerspruch gegen die Verarbeitung eingelegt;</li>
    <li>die personenbezogene Daten unrechtmäßig verarbeitet wurden;</li>
    <li>die personenbezogene Daten gelöscht werden müssen, um einer gesetzlichen Verpflichtung nachzukommen;</li>
    <li>Sie Ihre Einwilligung zur Verarbeitung personenbezogener Daten widerrufen haben und die personenbezogenen Daten
        auf der Grundlage Ihrer Einwilligung verarbeitet wurden und es keine andere Rechtsgrundlage für die Verarbeitung
        gibt.</li>
</ul>
<p>Sie können Ihr Recht auf Löschung jedoch nicht ausüben, wenn diese Daten unter anderem für die Feststellung oder
    Geltendmachung von Ansprüchen oder die Verteidigung gegen Ansprüche erforderlich sind.</p>
<h1>Recht auf Einschränkung der Datenverarbeitung</h1>
<p>Sie haben das Recht, die Einschränkung der Verarbeitung in den folgenden Fällen zu verlangen:</p>
<ul>
    <li>wenn Sie die Richtigkeit Ihrer personenbezogenen Daten bestreiten, für einen Zeitraum, der es Ihnen ermöglicht,
        die Richtigkeit dieser Daten zu überprüfen</li>
    <li>die Verarbeitung unrechtmäßig ist und Sie gegen die Löschung Ihrer personenbezogenen Daten Einspruch erheben und
        stattdessen eine Einschränkung ihrer Verwendung verlangen;</li>
    <li>die personenbezogenen Daten vom Verwalter nicht mehr benötigt werden, aber von Ihnen zur Feststellung,
        Geltendmachung oder Abwehr von Ansprüchen benötigt werden;</li>
    <li>wenn Sie Einspruch erheben, bis entschieden ist, ob die berechtigten Gründe des Verwalters die Gründe für Ihren
        Einspruch überwiegen.</li>
</ul>
<h1>Recht auf Datenübertragbarkeit</h1>
<p>Sie haben das Recht, Ihre personenbezogenen Daten, die Sie dem Verwalter zur Verfügung gestellt haben, in einem
    strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese personenbezogenen
    Daten ohne Behinderung durch den Verwalter an einen anderen für die Verarbeitung Verantwortlichen zu übermitteln,
    wenn:</p>
<ul>
    <li>die Verarbeitung erfolgt auf der Grundlage Ihrer Einwilligung oder auf der Grundlage eines Vertrags mit Ihnen
        und gleichzeitig;</li>
    <li>die Verarbeitung erfolgt mit automatischen Mitteln.</li>
</ul>
<p>Gleichzeitig haben Sie das Recht zu verlangen, dass die personenbezogenen Daten von dem für die Verarbeitung
    Verantwortlichen direkt an einen anderen für die Verarbeitung Verantwortlichen übermittelt werden, soweit dies
    technisch möglich ist.</p>
<h1>Recht auf Widerspruch gegen die Verarbeitung von Daten</h1>
<p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit Widerspruch gegen die
    Verarbeitung Ihrer Daten einzulegen, wenn die Rechtsgrundlage für die Verarbeitung das berechtigte Interesse des für
    die Verarbeitung Verantwortlichen ist.</p>
<p>Im Falle eines Widerspruchs wird der für die Verarbeitung Verantwortliche die Verarbeitung Ihrer personenbezogenen
    Daten einstellen, es sei denn, der für die Verarbeitung Verantwortliche weist nach, dass es für die Verarbeitung
    berechtigte Gründe gibt, die Ihre Interessen, Rechte und Freiheiten oder die Gründe für die Feststellung, Verfolgung
    oder Abwehr von Ansprüchen überwiegen.</p>
<p>Werden Ihre personenbezogenen Daten zu Zwecken des Direktmarketings verarbeitet, können Sie jederzeit gegen diese
    Verarbeitung, einschließlich des Profilings, Widerspruch einlegen, ohne dass Sie dafür Gründe anführen müssen, die
    sich auf Ihre besondere Situation beziehen, und der Verwalter wird diese Verarbeitung unverzüglich einstellen.</p>
<h1>Recht auf Beschwerde bei einer Aufsichtsbehörde</h1>
<p>Sie haben das Recht, sich beim Präsidenten der Datenschutzbehörde zu beschweren, wenn Sie der Meinung sind,</p>
<p>dass die Verarbeitung Ihrer personenbezogenen Daten gegen das diesbezügliche Gesetz verstößt.</p>
<h1>Informationen darüber, ob die Bereitstellung personenbezogener Daten eine gesetzliche oder vertragliche
    Verpflichtung oder eine Voraussetzung für den Abschluss eines Vertrags ist und ob keine Verpflichtung zur
    Bereitstellung der Daten besteht</h1>
<p>Die Angabe aller Daten ist freiwillig.</p>
<p>Je nach Situation, in der der Verwalter Ihre Daten erhält, kann die Bereitstellung bestimmter Daten (die vom
    Verwalter als erforderliche Daten definiert werden) eine Bedingung für den Abschluss eines Vertrags mit dem
    Verwalter oder eine Bedingung dafür sein, dass der Verwalter mit der Erbringung von Dienstleistungen beginnen kann,
    z. B. für die Eröffnung und Führung eines Kundenkontos durch den Verwalter.</p>
<p>Die Angabe von Adressdaten ist z.B. notwendig, um ein Angebot zu bestellen. Für zusätzliche Dienstleistungen wie
    Terminvereinbarungen kann eine Telefonnummer erforderlich sein. Die Angabe Ihres Namens, Ihrer Anschrift und Ihrer
    Umsatzsteuer-Identifikationsnummer kann gesetzlich vorgeschrieben und für die Erstellung von Aufzeichnungen für
    Steuerzwecke erforderlich sein.</p>
<p>Bei der Übermittlung von Nachrichten oder Anfragen an den Verwalter (z. B. zur Übermittlung eines Angebots) ist die
    Angabe von Kontaktdaten keine gesetzliche oder vertragliche Verpflichtung oder eine Bedingung für den Abschluss
    eines Vertrags, jedoch ist die Bereitstellung der erforderlichen Daten notwendig, damit der Verwalter antworten
    kann.</p>
<h1>Informationen über die Folgen einer Nichtbereitstellung der Daten</h1>
<p>Je nach Situation, in der der Verwalter Ihre Daten erhalten kann, kann die Nichtbereitstellung bestimmter
    Informationen Sie daran hindern, einen Vertrag mit dem Verwalter abzuschließen, zusätzliche Dienstleistungen in
    Anspruch zu nehmen, Ihr Kundenkonto zu eröffnen und zu führen, korrekte Steuerunterlagen vom Verwalter zu erhalten,
    ein Angebot vom Verwalter zu erhalten oder auf eine an den Verwalter gerichtete Nachricht zu antworten, eine
    Beschwerde, Reklamation, Intervention oder Anweisung zur Auftragsänderung an den Verwalter zu richten.</p>
<h1>Informationen über die Verwendung von Daten für die automatisierte Entscheidungsfindung, einschließlich Profiling
</h1>
<p>Ihre personenbezogenen Daten werden nicht für eine automatisierte Entscheidungsfindung, einschließlich Profiling,
    verwendet.</p>
<h1>Was sind Cookies und wie verwenden wir sie?</h1>
<p>Cookies (sog. "Kekse") sind IT-Daten, insbesondere Textdateien, die auf Ihrem Endgerät (z.B. Computer) gespeichert
    werden. Cookies enthalten in der Regel den Namen der Website, von der sie stammen, die Dauer der Speicherung auf dem
    Endgerät und eine eindeutige Nummer. Cookies werden verwendet für:</p>
<ul>
    <li>Anpassung des Inhalts der Website an die Präferenzen des Nutzers und Optimierung der Nutzung der Websites;
        insbesondere ermöglichen diese Dateien die Erkennung des Geräts des Website-Nutzers und die entsprechende
        Anzeige der Website, die an seine individuellen Bedürfnisse angepasst ist;</li>
    <li>Erstellung von Statistiken, die uns helfen zu verstehen, wie die Besucher der Website die Websites nutzen, damit
        wir deren Struktur und Inhalt verbessern können;</li>
    <li>zur Aufrechterhaltung der Benutzersitzung auf der Website.</li>
</ul>
<p>Die folgenden Arten von Cookies werden im Rahmen des Dienstes verwendet:</p>
<ul>
    <li>"notwendige" Cookies, um die Nutzung der auf der Website verfügbaren Dienste zu ermöglichen, z. B.
        Authentifizierungs-Cookies, die für Dienste verwendet werden, die eine Authentifizierung auf der Website
        erfordern; Sicherheits-Cookies, die z. B. dazu dienen, einen Missbrauch der Authentifizierung auf der Website
        aufzudecken;</li>
    <li>„Performance"-Cookies, die die Erfassung von Informationen über die Nutzung der Websites der Website
        ermöglichen;</li>
    <li>„funktionale" Cookies, die es ermöglichen, sich die vom Nutzer gewählten Einstellungen zu merken und die
        Benutzeroberfläche zu personalisieren, z. B. im Hinblick auf die vom Nutzer gewählte Sprache oder
        Herkunftsregion, die Schriftgröße, das Design der Website usw;</li>
    <li>"Werbe"-Cookies, die es ermöglichen, den Nutzern Werbeinhalte zu liefern, die besser auf ihre Interessen
        zugeschnitten sind.</li>
</ul>
<p>Standardmäßig erlauben Webbrowser (Ihre Software) die Speicherung von Cookies auf Ihrem Endgerät. Sie können Ihre
    Browsereinstellungen, die die Verwendung von Cookies bestimmen, jederzeit ändern. Beschränkungen bei der Verwendung
    von Cookies können die Funktionalität der Website beeinträchtigen. Die auf Ihrem Endgerät platzierten Cookies können
    auch von Werbetreibenden und Partnern, die mit dem Administrator zusammenarbeiten, verwendet werden. Wir möchten Sie
    darüber informieren, dass der Administrator für die Platzierung von Cookies auf Ihrem Endgerät und für den
    gleichzeitigen Zugriff auf diese Cookies verantwortlich ist. Informationen über den Umgang mit Cookies finden Sie in
    den Einstellungen Ihrer Software (Webbrowser). Weitere Informationen zu Cookies finden Sie zum Beispiel hier: http:
    <a href="http://wszystkoociasteczkach.pl/">//wszystkoociasteczkach.pl.</a></p>
<p>Eine Erklärung, wie Sie Ihre Cookie-Einstellungen ändern können, finden Sie im Hilfemenü Ihres Browsers. Sie sind
    unter den folgenden Links zu finden:</p>
<p>Internet Explorer™: https://support.microsoft.com/pl-pl/help/17442/windows-internet- explorer-delete-manage-cookies
</p>
<p><a href="http://safari.helpmax.net/pl/ochrona-i-prywatnosc/usuwanie-plikow-cookie/">Safari™:
        http://safari.helpmax.net/pl/ochrona-i-prywatnosc/usuwanie-plikow-cookie/</a> Chrome™:
    https://support.google.com/chrome/answer/95647?hl=pl&amp;hlrm=en Firefox™:
    https://support.mozilla.org/pl/kb/usuwanie-ciasteczek</p>
<p>Opera™: <a
        href="http://help.opera.com/Windows/12.10/pl/cookies.html">http://help.opera.com/Windows/12.10/pl/cookies.html</a>
</p>
<p>Wenn Sie mit der Verwendung von Cookies nicht einverstanden sind, kann die Funktionalität der Website eingeschränkt
    sein.</p>
    </div>
)