import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import GreenBox from 'src/assets/images/list-style-green.png'

export const MainTitle = styled.h1`
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  box-sizing: border-box;
  padding: 0 0.6rem;
  width: 100%;
  text-align: center;
  margin: 5.2rem 0 3.2rem 0;
  ${breakpoint.sm`
    font-size: 3.1rem;
  `}
`

export const ArticleContentWrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 3.2rem 10%;
`

export const Article = styled.article`
  display: block;
  max-width: 750px;
  width: 100%;
  box-sizing: border-box;
  margin: 3.6rem auto;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  padding: 1.8rem 5%;
  ${breakpoint.m`
    padding: 0;
  `}

  h1, h2, h3, h4, h5 {
    line-height: 1.8;
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
    color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  }

  h1 {
    margin-bottom: 2.6rem;
  }

  h2 {
    font-size: 2.1rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  p {
    color: ${({ theme }) => theme.colors.brightGray.hex()};
    margin-bottom: 1rem;
    line-height: 1.7;
    font-size: 1.1rem;
    & > em,
    & > strong {
      font-weight: 600;
    }
  }

  ul {
    list-style-type: circle;
    display: inline-block;
    padding-left: 2.8rem;
    margin: 0.8rem 0;
    list-style: none;
    li {
      margin-bottom: 0.6rem;
      padding-left: 0.8rem;
      position: relative;
      line-height: 1.6;
      font-size: 1.1rem;
      &:before {
        content: '';
        background-image: url(${GreenBox});
        background-color: white;
        background-size: contain;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 6px;
        left: -16px;
      }
    }
  }

  ol {
    display: inline-block;
    padding-left: 2.8rem;
    margin: 0.8rem 0;
    li {
      margin-bottom: 0.6rem;
      padding-left: 0.8rem;
      line-height: 1.6;
      font-size: 1.1rem;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    text-decoration: underline;
    font-style: normal;
    font-weight: 600;
  }

  em {
    font-style: normal;
  }

  blockquote {
    margin-right: 0;
    margin-left: 0;
  }

  blockquote > p {
    line-height: 1.6;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
    color: ${({ theme }) => theme.colors.brightGray.hex()};
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1.2rem;
    min-width: 100%;
    box-sizing: border-box;
    overflow: auto;

    thead tr th {
      padding: 0.6rem 0;
      strong {
        font-weight: 800;
      }
    }
    tbody tr td {
      padding: 0.8rem 0.6rem;
    }
  }

  table,
  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const DownloadFiles = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin-bottom: 1.6rem;
  > span {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }
  ul {
    list-style-type: circle;
    display: inline-block;
    padding-left: 2.8rem;
    margin: 0.8rem 0;
    list-style: none;
    li {
      margin-bottom: 0.6rem;
      padding-left: 0.8rem;
      position: relative;
      line-height: 1.6;
      font-size: 1.1rem;
      &:before {
        content: '';
        background-image: url(${GreenBox});
        background-color: white;
        background-size: contain;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 6px;
        left: -16px;
      }
    }
  }
`
