import React from 'react'

export default () => (
    <div>
        <h1>PRIVACY AND COOKIES POLICY</h1>
<p>Due to the entry into force on 25 May 2018 of Regulation (EU) 2016/679 of the European Parliament and of the Council
    of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free
    movement of such data and repealing Directive 95/46/EC (Official Journal of the European Union L 119 p. 1) - the
    so-called GDPR - we are committed to respecting your right to privacy on the Internet and making every effort to
    properly secure any personal data provided. At the same time, we emphasize that the website <a
        href="http://www.kontenerowy.pl/">www.kontenerowy.pl</a> (the "Website") can be viewed without providing your
    personal information.</p>
<h1>Personal Data Administrator:</h1>
<p>The administrator of your personal data ("Administrator") is RR Media spółka z ograniczoną odpowiedzialnością with
    its registered office in Gdynia (81-339) at ul. Polska 19, e-mail <a
        href="mailto:biuro@e-containers.eu">biuro@e-containers.eu,</a> tel. +48 58 622 39 01.</p>
<h1>Data Protection Officer:</h1>
<p>The Administrator has not appointed a Data Protection Officer. However, we undertake to take appropriate measures to
    protect against unauthorized access or disclosure of your data to third parties. Our databases are secured from
    third party access. If you have any doubts about the processing of your data, do not hesitate to contact the
    Administrator using the contact details indicated above.</p>
<h1>Purposes and legal basis of data processing, legitimate interest of processing</h1>
<p>Below we present to you all the purposes of data processing by the Administrator that occur as standard in the
    Administrator's activities. The specific purpose of processing your data is communicated to you each time by the
    Administrator at the time of obtaining your data. Your personal data may thus be processed for the purpose of:</p>
<ul>
    <li>entering into and performing the agreement(s), including providing transaction processing, resolving problems
        and fulfilling your requests that you address to us - as the processing is necessary for entering into and
        performing the agreement(s) (Article 6(1)(b) of the GDPR),</li>
    <li>providing services by electronic means and sending commercial information - on the basis of your consent
        (Article 6(1)(a) of the GDPR in connection with Article 10(2) of the Act on providing services by electronic
        means),</li>
    <li>direct marketing of products or services, including establishing contact, presenting an offer, conducting
        measurements and analyses, including profiling (i.e. using personal data to create your profile in terms of
        products and services in which you may be interested), storing data in cookies and collecting data from websites
        and mobile applications - on the basis of your consent (Article 6(1)(a) of the GDPR in connection with Article
        172 of the Telecommunications Law),</li>
    <li>complying with our legal obligations under EU or Polish law (e.g. to the President of the Office of Competition
        and Consumer Protection, tax authorities) - because processing is necessary for the fulfillment of legal
        requirements to which we are subject (Article 6(1)(c) of the GDPR),</li>
    <li>providing payment services, ensuring the security of the services we provide electronically, handling your
        requests submitted e.g. through the contact form in a situation where they are not directly related to the
        performance of the agreement, debt collection, conducting judicial, administrative and mediation proceedings,
        storing data for archival purposes and ensuring accountability (fulfillment of obligations under the law) -
        because processing is necessary for the realization of our legitimate interest, which is the operation of
        services provided, the fulfillment of your requests, the ability to establish, assert or defend against claims,
        archiving of data (Article 6(1)(f) of the GDPR).</li>
</ul>
<h1>Newsletter</h1>
<p>If you sign up for our newsletter, then we will use the data necessary for this purpose or provided to us separately
    by you for the purpose of sending our newsletter by email on a regular basis based on your consent.</p>
<p>You can unsubscribe from the newsletter at any time by sending us a message to that effect or by using the
    appropriate link provided in the newsletter. Once you unsubscribe, we will delete your e-mail address unless you
    expressly consent to the continued use of your data for other purposes or we reserve the right to continue using
    your data in statutorily permitted cases.</p>
<h1>To whom and where may the Administrator transfer your data?</h1>
<p>Your data will be accessed, in addition to employees, agents and members of the Administrator's bodies, by persons
    and entities that provide operation, maintenance and service of the systems and IT solutions used by the
    Administrator. In addition, depending on the nature of the case, your personal data may be transferred by the
    Administrator only to the following entities:</p>
<ul>
    <li>entities cooperating with the Administrator and performing services necessary in the course of the
        Administrator's business, in particular, entities providing accounting, bookkeeping, financial, legal or tax
        consulting services to the Administrator - whereby such entities process data on the basis of an agreement with
        the Administrator and only in accordance with the Administrator's instructions.</li>
    <li>public authorities, including courts, upon their reasonable request.</li>
</ul>
<p>In order to perform the agreement, we transfer your data to the delivery courier company, insofar as this is
    necessary for the delivery of the ordered goods.</p>
<p>Depending on which payment service provider you select during the order process, in order to process your payment, we
    transfer the payment data collected for this purpose to the credit institution handling the payment and possibly to
    the payment service provider selected by us or by you. Some payment service providers collect the data themselves if
    you create an account with them. In such cases, you will need to log in to the payment service provider with your
    access data as part of your order. The privacy policy of the respective payment service provider will then also
    apply.</p>
<h1>Use of Google (Universal) Analytics for web analytics purposes</h1>
<p>The Website uses Google (Universal) Analytics, a web analytics tool from Google Inc. (www.google.com). The above - as
    part of the analysis and evaluation of interests - serves to protect our legitimate interest in optimally presenting
    our offerings. Google (Universal) Analytics uses methods that make it possible to analyze your use of the website -
    for example, cookies. The automatically collected information regarding your use of the Website is generally
    transmitted to a Google server in the United States and stored there. Due to the IP anonymization activated on the
    Website, your IP address is truncated before being forwarded further within the member states of the European Union
    or other countries party to the Agreement on the European Economic Area.</p>
<p> Only in exceptional cases the full IP address is transmitted to a Google server in the United States and shortened
    there. The anonymized IP address transmitted by your browser within the scope of Google Analytics is generally not
    combined with other Google data. Google LLC is headquartered in the USA and is certified by EU-US-Privacy Shield.
    The current certificate is available <strong>at this link.</strong> As part of the agreement between the US and the
    European Commission, the latter has stated an adequate level of data protection for companies with Privacy Shield
    certification. You can prevent the data collected by cookies about your use of our Website (including your IP
    address) from being recorded by Google, as well as the processing of this data by Google, if you download and
    install the browser plug-in found <strong>at this link.</strong> Alternatively - instead of installing the browser
    plug-in, you can also click on this link to disable data collection by Google Analytics Service. An opt-out cookie
    will then be stored on your terminal device. If you delete the cookies, then you must click on the above link again.
</p>
<h1>Google AdWords Remarketing</h1>
<p>With the help of Google Adwords, we promote the Service in search results and on third-party websites. For this
    purpose, when you visit our website, a so-called Google Remarketing cookie is automatically left on each visitor's
    device, which, with the help of a pseudonymous identifier (ID) and based on the pages you visit, enables
    interest-based advertising. The above - as part of the analysis and evaluation of interests - serves to protect our
    legitimate interest in optimally presenting our offerings. Further data processing only takes place if you agree to
    Google linking your browsing history and application usage to your account and using information from your Google
    account to personalize the ads that are displayed on the websites. If you are then logged in when you visit our
    website on Google, Google will use your data together with Google Analytics data to create and define target group
    lists for remarketing purposes on different devices. For this purpose, Google temporarily combines your personal
    data with Google Analytics data to create target groups. The Google AdWords Remarketing service is an offering of
    Google LLC (www.google.pl). Google LLC is headquartered in the USA and is certified by EU-US-Privacy Shield. The
    current certificate is available <strong>at this link.</strong> As part of the agreement between the US and the
    European Commission, the latter has stated an adequate level of data protection for companies with Privacy Shield
    certification. You can deactivate cookies used for remarketing by clicking on this <strong>link</strong>. In
    addition, you can consult the <strong>Digital Advertising Alliance</strong> website for information on the use of
    cookies and the corresponding settings.</p>
<h1>Transfer of data outside the European Economic Area or to international organizations</h1>
<p>Except as described above, your personal data will not be transferred outside the European Economic Area or to
    international organizations.</p>
<h1>How long will the Administrator keep your data? (information about the period of data storage)</h1>
<p>The period of storage of your data by the Administrator will depend on the purpose of processing your data.</p>
<p>In case your data is processed by the Administrator for the performance of an agreement between you and the
    Administrator or for the performance of other obligations to you by the Administrator (e.g. to maintain a customer
    account), your personal data will be retained by the Administrator for the period necessary to perform the
    Administrator's obligations to you, e.g. under a sales agreement, and until the expiration of the statute of
    limitations for any claims you may have against the Administrator and the Administrator's claims against you related
    to such obligations, e.g. arising from an agreement of sale, unless the retention of data is necessary longer for
    the purposes of tax obligations, in which case deletion will take place immediately after the expiration of the
    statute of limitations for tax obligations related to the obligation, e.g. the concluded agreement (e.g. for billing
    purposes related to a given agreement, your data will be retained for 5 years counting from the end of the calendar
    year in which the deadline for payment of tax related to the agreement expired).</p>
<p>In case your data is processed by the Administrator due to the necessity of the processing for the purposes arising
    from the Administrator's legitimate interests, your personal data will be kept by the Administrator for the period
    necessary to fulfill the purposes arising from such interests, whereby for the purpose of direct marketing for the
    period of existence of the legitimate interest to conduct marketing, unless you exercise your right and object to
    the processing of such data for marketing purposes. In this case, the Administrator may keep your data for the
    period of the statute of limitations for your claims against the Administrator, or the Administrator's claims
    against you under the law, if the processing of such data is necessary for the purpose of establishing or asserting
    claims, as well as for the purpose of defending against such claims.</p>
<p>For the realization of the Administrator's legitimate interests involving the establishment and assertion of claims
    or defense against claims, the Administrator may keep your data for the period of limitation of your claims against
    the Administrator or the Administrator's claims against you resulting from the law (for example, the general
    limitation period for claims related to business activities is 3 years, while the general limitation period for
    consumer claims against the Administrator is 10 years; the aforementioned personal data retention periods may change
    with changes in generally applicable laws).</p>
<p>For the fulfillment of the Administrator's legitimate interests involving responding to your message, question,
    complaint or suggestion, the Administrator may store your data for the period necessary to respond or resolve the
    matter with which you address the Administrator, whereby the Administrator may extend this period by the statute of
    limitations for your claims against the Administrator, or the Administrator's claims against you under the law, if
    the processing of such data is necessary for the purpose of establishing or asserting claims, as well as for the
    purpose of defending against such claims.</p>
<p>In addition, in the event of referral of claims against the Administrator or by the Administrator, e.g. in connection
    with a sales agreement between you and the Administrator, your data will be stored for no longer than is necessary
    in connection with the relevant proceedings concerning such claims.</p>
<p>If your data is processed by the Administrator due to a legal obligation of the Administrator, the Administrator will
    keep the data for as long as the relevant legal obligation of the Administrator. If your data is necessary to
    document business events for accounting purposes, your data in this regard will be kept by the Administrator as long
    as there is a legal obligation of the Administrator to have a document of these events.</p>
<h1>Information about your rights <br />Right of access to data</h1>
<p>You have the right to request access to your personal data, including, in particular, information about whether the
    Administrator processes your personal data and the scope of data held by the Administrator, the purposes of data
    processing or the categories of recipients of your data, as well as, if possible, the planned period of data
    storage, your rights regarding your personal data, information about the sources of the Administrator's acquisition
    of your data, if not collected from you.</p>
<p> In addition, you have the right to obtain a copy of the data, with the proviso that obtaining the first copy of the
    data is free of charge, and obtaining each subsequent copy may require the payment of a reasonable fee that takes
    into account the administrative costs of preparing such a copy of the data.</p>
<h1>Right to rectification of data</h1>
<p>You have the right to request immediate rectification of inaccurate data or, taking into account the purposes of
    processing, completion of incomplete data.</p>
<h1>Right to erasure of data (right to be forgotten)</h1>
<p>You have the right to request the erasure of your data if one of the following circumstances applies:</p>
<ul>
    <li>your personal data are no longer necessary for the purposes which they were collected for or otherwise
        processed;</li>
    <li>you have raised an effective objection to the processing;</li>
    <li>the personal data has been processed unlawfully;</li>
    <li>your personal data must be deleted in order to comply with a legal obligation;</li>
    <li>you have withdrawn your consent to the processing of personal data, the personal data was processed on the basis
        of your consent and there is no other legal basis for processing.</li>
</ul>
<p>However, you will not be able to exercise your right to erasure, among other things, if such data is necessary to
    establish or assert claims or defend against claims.</p>
<h1>Right to restrict processing</h1>
<p>You have the right to request restriction of processing in the following cases:</p>
<ul>
    <li>if you question the accuracy of your personal data - for a period that allows you to check the accuracy of the
        data</li>
    <li>the processing is unlawful and you object to the deletion of the personal data, requesting instead a restriction
        on its use;</li>
    <li>the personal data is no longer needed by the Administrator but is needed by you to establish, assert or defend
        against claims;</li>
    <li>if you object to it - until it is determined whether the legitimate grounds on the part of the Administrator
        override the grounds for your objection.</li>
</ul>
<h1>Right to data portability</h1>
<p>You have the right to receive in a structured, commonly used machine-readable format your personal data that you have
    provided to the Administrator, and you have the right to send this personal data to another Administrator without
    hindrance from the Administrator, if:</p>
<ul>
    <li>the processing is based on your consent or on the basis of a contract with you and; at the same time</li>
    <li>the processing is carried out by automated means.</li>
</ul>
<p>At the same time, you have the right to request that the personal data be sent by the Administrator directly to
    another administrator, if technically possible.</p>
<h1>Right to object to data processing</h1>
<p>You have the right to object at any time - on grounds related to your particular situation - to the processing of
    your data if the legal basis for the processing is the legitimate interest of the Administrator.</p>
<p>As a result of an objection, the Administrator will cease processing your personal data unless it demonstrates that
    there are valid legitimate grounds for processing that override your interests, rights and freedoms, or grounds for
    establishing, pursuing or defending against claims.</p>
<p>If your personal data is processed for the purposes of direct marketing, you may object to such processing, including
    profiling, at any time without having to demonstrate reasons relating to your particular situation and the
    Administrator shall immediately cease such processing.</p>
<h1>Right to lodge a complaint to a supervisory authority</h1>
<p>You have the right to lodge a complaint with the President of the Office for Personal Data Protection if you believe
    that the processing of your personal data violates the law in this regard.</p>
<h1>Information on whether providing personal data is a statutory or contractual requirement or a condition for entering
    into an agreement, and on the absence of an obligation to provide data</h1>
<p>You provide all data voluntarily.</p>
<p>Depending on the situation in which the Administrator may obtain your data, providing certain data (defined by the
    Administrator as required data) may be a condition for entering into a contract with the Administrator or a
    condition for the Administrator to begin providing services, such as opening and maintaining a customer account.</p>
<p>Providing address data is necessary, for example, to order an offer. Providing a telephone number may be necessary to
    take advantage of additional services involving, for example, arranging an appointment. Provision of name, address
    and Tax Identification Number may be a statutory requirement and necessary for the preparation of documentation for
    tax purposes.</p>
<p>In the case of directing messages or requests to the Administrator (e.g. to send an offer), providing contact details
    is not a statutory or contractual requirement or a condition for concluding an agreement, however, providing the
    required data is necessary to enable the Administrator to respond.</p>
<h1>Information about the consequences of not providing data</h1>
<p>Depending on the situation in which the Administrator may obtain your data, failure to provide certain information
    may prevent you from entering into an agreement with the Administrator, taking advantage of additional services,
    opening and maintaining your customer account, receiving correct tax documents from the Administrator, receiving an
    offer from the Administrator or responding to a message addressed to the Administrator, submitting a complaint,
    intervention or change of order instruction to the Administrator.</p>
<h1>Information about the use of data for automated decision-making, including profiling</h1>
<p>Your personal data will not be used for automated decision-making, including profiling.</p>
<h1>What are cookies and how we use them</h1>
<p>Cookies (so-called "cookies") are IT data, in particular text files, which are stored on your terminal device (e.g.
    computer). Cookies usually contain the name of the website they come from, the time they are stored on your end
    device and a unique number. Cookies are used for:</p>
<ul>
    <li>adapting the content of the Website to the user's preferences and optimizing the use of the websites; in
        particular, these files allow for recognition of the Website user's device and appropriate display of the
        website, adjusted to his individual needs;</li>
    <li>creating statistics that help to understand how users of the Website use it, which makes it possible to improve
        its structure and content;</li>
    <li>maintaining a session of a user of the Website.</li>
</ul>
<p>The following types of cookies are used within the Website:</p>
<ul>
    <li>"necessary" cookies, enabling use of services available within the Website, e.g. authentication cookies used for
        services requiring authentication within the Website; cookies used to ensure security, e.g. used to detect abuse
        of authentication within the Website;</li>
    <li>"performance" cookies, enabling the collection of information about how the Website's pages are used;</li>
    <li>"functional" cookies, making it possible to "remember" the User's selected settings and personalize the User's
        interface, e.g. with regard to the selected language or region of origin of the User, font size, web page
        layout, etc.;</li>
    <li>"advertising" cookies, allowing to provide Users with advertising content more tailored to their interests.</li>
</ul>
<p>Web browsers (your software) allow cookies to be stored on your end device by default. You can always change your
    browser settings that determine the use of cookies. Restrictions on the use of cookies may affect the functionality
    of the Website. Cookies placed on your end device may also be used by advertisers and partners cooperating with the
    Administrator. Please be advised that the Administrator is the entity responsible for placing cookies on your end
    device while having access to them. Information on how to use cookies is available in the settings of your software
    (web browser). More information about cookies is available, for example, here <a
        href="http://wszystkoociasteczkach.pl/">http://wszystkoociasteczkach.pl.</a></p>
<p>In the help menu of your web browser you will find explanations on how to change your cookie settings. They are
    available at the following links:</p>
<p>Internet Explorer™: https://support.microsoft.com/pl-pl/help/17442/windows-internet- explorer-delete-manage-cookies
</p>
<p><a href="http://safari.helpmax.net/pl/ochrona-i-prywatnosc/usuwanie-plikow-cookie/">Safari™:
        http://safari.helpmax.net/pl/ochrona-i-prywatnosc/usuwanie-plikow-cookie/</a> Chrome™:
    https://support.google.com/chrome/answer/95647?hl=pl&amp;hlrm=en Firefox™:
    https://support.mozilla.org/pl/kb/usuwanie-ciasteczek</p>
<p><a href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera™:
        http://help.opera.com/Windows/12.10/pl/cookies.html</a></p>
<p>If you do not agree to the use of cookies, the functionality of the Website may be limited.</p>
    </div>
)