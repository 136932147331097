import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import { useIntl } from 'gatsby-plugin-intl'
import {
  MainTitle,
  ArticleContentWrapper,
  Article
} from 'src/components/static/common'
import PrivacyPolicyAndCookiesPL from 'src/components/static/privacyPolicyAndCookies/PrivacyPolicyAndCookiesContentPL'
import PrivacyPolicyAndCookiesDE from 'src/components/static/privacyPolicyAndCookies/PrivacyPolicyAndCookiesContentDE'
import PrivacyPolicyAndCookiesEN from 'src/components/static/privacyPolicyAndCookies/PrivacyPolicyAndCookiesContentEN'

export default () => {
  
  const intl = useIntl();

  const renderPrivacyPolicyAndCookiesContent =  (locale) => {
    switch (locale) {
      case 'pl':
        return <PrivacyPolicyAndCookiesPL />
      case 'en':
        return <PrivacyPolicyAndCookiesEN />
      case 'de': 
        return <PrivacyPolicyAndCookiesDE />
      default:
        return <PrivacyPolicyAndCookiesPL />
    }
  }

  return (
  <ThemeProvider>
    <Layout>
      <MainTitle>{intl.formatMessage({id: "regulations.privacyPolicyAndCookies"})}</MainTitle>
      <ArticleContentWrapper>
        <Article> 
          {renderPrivacyPolicyAndCookiesContent(intl.locale)}
        </Article>
      </ArticleContentWrapper>
    </Layout>
  </ThemeProvider>
);
}
